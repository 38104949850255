@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Outfit', sans-serif;
}

a{
  text-decoration: none;
}

h1 caption{
  font-size: 0.5em;
  color: #5122B2;
}

.page{
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-image: url("../images/bg.png");
  background-size: cover;
  /* background: linear-gradient(#E9ECFF, #EFEAF6); */
}

a, button{
  cursor: pointer;
}

input.button{
  text-transform: uppercase;
  width: 100%;
}

.button{
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #5122B2;
  color: white;
  text-decoration: none;
  padding: 1.1em;
  font-size: 1.1em;
  border-radius: 10em;
  border: none;
  height: 3.75em;
}

.button.centered{
  justify-content: center;
}

.button.outline{
  background: none;
  color: #5122B2;
  font-weight: bold;
  border: 6px solid #5122B2;
}

input.button.outline::placeholder{
  color: #5122B2;
  text-transform: none;
}

.button:hover{
  background-color: #351378;
}

.button.outline:hover{
  background-color: #21212f10;
}

.button .material-symbols-outlined{
  margin-left: 0.5em;
}

nav{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3em;
  padding-bottom: 0;
}

nav a.logo{
  width: fit-content;
}

nav > *{
  width: 100%;
}

nav img{
  height: 3em;
}

nav .llinks a{
  margin-right: 2em;
}

span.icon{
  margin-right: 0.5em;
}

nav .llinks .notif{
  font-size: 1em;
  background-color: #4A4A67;
  color: white;
  padding: 0.5em;
  border-radius: 1em;
}

nav .rlinks{
  text-align: right;
}

nav .rlinks a{
  margin-right: 1em;
}

nav .rlinks .primary{
  font-size: 1.25em;
  padding: 0.7em 1.4em 0.7em 1.4em;
  border: solid 0.15em #21212F;
  border-radius: 10em;
  background: none;
  cursor: pointer;
}

nav .rlinks .primary:hover{
  background-color: #21212f10;
}

nav a{
  text-decoration: none;
  color: #21212F;
  font-size: 1.25em;
}

main{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

main h1{
  font-size: 4em;
}

main header{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.line1{
  font-size: 0.4em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: orangered;
}

.dock{
  display: flex;
  justify-content: center;
  padding: 1em;
  background-color: #2D2D40;
}

.dock a{
  display: flex;
  align-items: center;
  font-size: 1.2em;
  margin-top: -2em;
  margin-right: 3em;
  background-color: #4A4A67;
  color: white;
  text-decoration: none;
  padding: 0.5em;
  padding-right: 1em;
  border: solid 10px #21212F;
  border-radius: 1.2em;
}

.dock a.primary{
  background-color: #5122B2;
  padding: 0.7em 2em 0.7em 2em;
  border-radius: 10em;
}

.dock a.primary span{
  margin-left: 0.5em;
}

.dock a:hover{
  background-color: #21212F;
}

.dock a p .caption{
  display: block;
  font-size: 0.8em;
  opacity: 0.7;
}

.dock a img{
  height: 2.75em;
  margin-right: 0.5em;
}

.dock a:last-child{
  margin-right: 0;
}

form{
  width: 25vw;
}

form > *{
  width: 100%;
}

form a{
  text-align: left;
  width: 100%;
  text-decoration: none;
  color: black;
  margin-bottom: 1em;
}

form.account{
  display: flex;
  flex-direction: column;
  align-items: center;
}

form .error{
  padding: 1em;
  border: solid 5px #21212F;
  background-color: #4A4A67;
  color: white;
  border-radius: 1em;
  margin-bottom: 1em;
}

form.account input{
  display: block; 
  padding: 1em;
  margin-bottom: 1em;
  font-size: 1.1em;
  border-radius: 1.2em;
  background-color: #D0D0F4;
  border: solid 7px #21212F;
}

form.account input::placeholder{
  color: #000000;
  opacity: 0.5;
}

footer{
  padding: 1em;
  text-align: center;
}

footer a{
  text-decoration: none;
  color: black;
}